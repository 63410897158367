<app-dark-nav></app-dark-nav>

<section class="gif-parent">
  <div class="project-info">
    <div class="project-bio">
      <p class="counter">SECRETS</p>
      <p class="project-headline">
        <strong>Dear Young Designer —</strong> Here's all I've learned so far...
      </p>
    </div>
    <!-- Right side of project content -->
    <div class="project-links">
      <!-- Button #1 -->
      <div class="project-link">
        <a
          aria-label="Back Home"
          id="faArrowLeft"
          [routerLink]="'/portfolio'"
          fragment="bio-anchor"
          ><fa-icon [icon]="faArrowLeft" size="2x"></fa-icon
        ></a>
        <p>Back<br />Home</p>
      </div>
      <!-- Button #2 -->
    </div>
  </div>
  <!-- END OF TOP SECTION -->
  <div class="grid-container">
    <!-- Group #1 -->
    <div class="grid-group">
      <!-- Secret #1 -->
      <div class="secret default-white">
        <img src="assets/PNG/star.png" alt="gold star" />
        <p class="secret-head">Design change for the better.</p>
        <p>Don’t just sell shit.</p>
      </div>
      <!-- Secret #2 -->
      <div class="secret hover-white">
        <img src="assets/PNG/star.png" alt="gold star" />
        <p class="secret-head">
          If you’re wondering if everyone in the room is bullshitting,
        </p>
        <p>they are.</p>
      </div>
    </div>
    <!-- Group #2 -->
    <div class="grid-group">
      <!-- Secret #3 -->
      <div class="secret hover-white">
        <img src="assets/PNG/star.png" alt="gold star" />
        <p class="secret-head">
          If you keep hitting a wall and don’t know how to go forward,
        </p>
        <p>
          don’t go forward at all. Go the opposite direction. Do something
          different and come back later. The door will open.
        </p>
      </div>
      <!-- Secret #4 -->
      <div class="secret hover-white">
        <img src="assets/PNG/star.png" alt="gold star" />
        <p class="secret-head">Do it scared.</p>
        <p>Change is scary, but so is staying the same.</p>
      </div>
    </div>
    <!-- Group #3 -->
    <div class="grid-group">
      <!-- Secret #5 -->
      <div class="secret hover-white">
        <img src="assets/PNG/star.png" alt="gold star" />
        <p class="secret-head">Evolve or Die.</p>
        <p>Boredom is the enemy.</p>
      </div>
      <!-- Secret #6 -->
      <div class="secret hover-white">
        <img src="assets/PNG/star.png" alt="gold star" />
        <p class="secret-head">
          If you’re given a small task that nobody else wants to do,
        </p>
        <p>
          put everything you can into it. Even the small stuff can be a big
          opportunity, if you make it one.
        </p>
      </div>
    </div>
  </div>
</section>

<!-- OLD CODE -->

<!-- Navigation -->
<!-- 
<div class="secrets-page" #circle (mousemove)="onMouseMove(circle)">
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <header id="bio-anchor" class="nav-hover">
    <div class="logo-img">
      <a [routerLink]="'/portfolio'" aria-label="Home"
        ><img src="/assets/SVG/peb-logo.svg" alt="PEB logo"
      /></a>
    </div>
    <nav>
      <ul class="nav-links">
        <li>
          <a [routerLink]="'/portfolio'" fragment="work-anchor">WORK →</a>
        </li>
        <li>
          <a [routerLink]="'/portfolio'" fragment="resume-anchor">RESUME →</a>
        </li>
        <li>
          <a id="secret-star" [routerLink]="'/portfolio'"
            ><img src="assets/PNG/star.png" alt="gold star"
          /></a>
        </li>
      </ul>
    </nav>
  </header>

  End Navigation -->

<!-- <section class="secrets-container" #cirle (mousemove)="onMouseMove(circle)"> -->
<!-- <div class="circle" [style.top.px]="y" [style.left.px]="x"></div> -->
<!-- <div class="secrets-top"> -->
<!-- Left side of bio content -->
<!-- <div class="secrets-words-container">
      <div class="secrets-header">
        <h1>Dear young designer,</h1>
      </div>
    </div> -->
<!-- Back Button -->
<!-- <div class="back">
      <a [routerLink]="'/portfolio'" aria-label="Back To Portfolio"
        ><fa-icon [icon]="faArrowLeft" size="2x"></fa-icon
      ></a>
      <p>BACK</p>
    </div>
  </div>

  <div class="secrets-list"> -->
<!-- Item 1 -->
<!-- <div class="secret-item" id="sec-01">
      <p class="secret-number secret-bold">01</p>
      <div class="secret-words">
        <p class="secret-bold">Design change for the better.</p>
        <p>Don't just sell shit.</p>
      </div>
    </div> -->
<!-- Item 2 -->
<!-- <div class="secret-item" id="sec-02">
      <p class="secret-number secret-bold">02</p>
      <div class="secret-words">
        <p class="secret-bold">Assume nothing.</p>
        <p>Take only what you need. Don't be greedy.</p>
      </div>
    </div> -->
<!-- Item 3 -->
<!-- <div class="secret-item" id="sec-03">
      <p class="secret-number secret-bold">03</p>
      <div class="secret-words">
        <p class="secret-bold">
          If you're wondering if everyone in the room is bullshitting,
        </p>
        <p>they are.</p>
      </div>
    </div> -->
<!-- Item 4
    <div class="secret-item" id="sec-04">
      <p class="secret-number secret-bold">04</p>
      <div class="secret-words">
        <p class="secret-bold">
          If you keep hitting a wall and don't know how to go forward,
        </p>
        <p>
          don't go forward at all. Go the opposite direction. Do something
          different and come back later. The door will open.
        </p>
      </div>
    </div> -->
<!-- Item 5 -->
<!-- <div class="secret-item" id="sec-05">
      <p class="secret-number secret-bold">05</p>
      <div class="secret-words">
        <p class="secret-bold">
          If you're given a small task that nobody else wants to do,
        </p>
        <p>
          put everything you can into it. Even the small stuff can be a big
          opportunity, if you make it one.
        </p>
      </div>
    </div> -->
<!-- End Items -->
<!-- </div>

  <div class="secrets-image-container"> -->
<!-- <img src="assets/PNG/portfolio-collage.png" /> -->
<!-- </div>
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
</section> -->

<!-- </div> -->
