<section id="about-parent" #circle (mousemove)="onMouseMove(circle)">
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <div class="about">
    <!-- Paragraph Section -->
    <div class="about-paragraph">
      <p class="about-eyebrow">ABOUT</p>
      <p class="about-headline">Designer, Developer, Daydream Believer</p>
      <p class="about-body">
        I am a highly motivated designer who is looking to use my skill set for
        good: to change the world, not just sell it. I use my problem-solving
        skills to introduce socially-conscious, human-centered designs that
        prioritize equity, accessibility and sustainability.
      </p>
    </div>
    <!-- Fun Facts -->
    <div class="fun-facts">
      <!-- Start Fact #1 -->
      <div class="fact">
        <div class="fact-category">
          <p class="emoji">🥕</p>
          <p class="fact-label">Favorite Snack(s)</p>
        </div>
        <p class="fact-answer">Truffle fries & garlic aioli</p>
      </div>
      <!-- End fact #1 -->
      <!-- Start Fact #2 -->
      <div class="fact">
        <div class="fact-category">
          <p class="emoji">🎧</p>
          <p class="fact-label">Currently Listening to</p>
        </div>
        <p class="fact-answer">The Morning Toast, Punk Rock Demi, Lizzo</p>
      </div>
      <!-- End fact #2 -->
      <!-- Start Fact #3 -->
      <div class="fact">
        <div class="fact-category">
          <p class="emoji">🎨</p>
          <p class="fact-label">Design Inspiration</p>
        </div>
        <p class="fact-answer">
          Tobias Van Schneider, Deva Pardue, Corita Kent
        </p>
      </div>
      <!-- End fact #3 -->
      <!-- Start Fact #4 -->
      <div class="fact">
        <div class="fact-category">
          <p class="emoji">⭐</p>
          <p class="fact-label">Favorite Influencer(s)</p>
        </div>
        <p class="fact-answer">Jojo Siwa, Remi Bader</p>
      </div>
      <!-- End fact #4 -->
      <!-- Start Fact #5 -->
      <div class="fact">
        <div class="fact-category">
          <p class="emoji">🍿</p>
          <p class="fact-label">Currently Streaming</p>
        </div>
        <p class="fact-answer">Glee, MTV’s the Challenge</p>
      </div>
      <!-- End fact #5 -->
      <!-- Start Fact #6 -->
      <div class="fact">
        <div class="fact-category">
          <p class="emoji">🎯</p>
          <p class="fact-label">Hobbies</p>
        </div>
        <p class="fact-answer">
          Vegan baking, running (slowly), learning Spanish
        </p>
      </div>
      <!-- End fact #6 -->
      <!-- Start Fact #7 -->
      <div class="fact">
        <div class="fact-category">
          <p class="emoji">👑</p>
          <p class="fact-label">Currently obsessed with</p>
        </div>
        <p class="fact-answer">
          Cynthia Erivo, platform Crocs, rug cleaning ASMR
        </p>
      </div>
      <!-- End fact #7 -->
    </div>
    <!-- Back to Top -->
    <div class="back-to-top">
      <a
        aria-label="Back To Top"
        id="faArrowUp"
        [routerLink]="'/portfolio'"
        fragment="bio-anchor"
        ><fa-icon [icon]="faArrowUp" size="2x"></fa-icon
      ></a>
      <p>Back To Top</p>
    </div>
  </div>
</section>
