<app-navigation></app-navigation>

<section class="project-parent" #cirle (mousemove)="onMouseMove(circle)">
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <!-- Left side of project content -->
  <div class="project-info">
    <div class="project-bio">
      <p class="counter"><strong>06/</strong>09</p>
      <p class="project-headline">
        <strong>Byte Me —</strong> Balancing form and function of mobile-first
        applications
      </p>
      <div class="project-hero">
        <img src="/assets/PNG/byte-me-hero.png" alt="Byte Me Hero" />
      </div>
      <div class="project-meta">
        <p><strong>PROJECT:</strong> Academic</p>
        <p><strong>ROLE:</strong> Ui/UX & Development</p>
        <p><strong>DATE:</strong> 2021</p>
      </div>
      <p class="project-info">
        How an application looks and how it works are equally important when
        calculating its success. When given the task of developing an app, my
        team payed attention to popular recipe websites that are usually bloated
        with blog posts and challenging UX. Byte Me was our happy balance of
        form and function that simplifies the handheld experience to be
        accessible to aspiring chefs at any level.
      </p>
    </div>
    <!-- Right side of project content -->
    <div class="project-links">
      <!-- Button #1 -->
      <div class="project-link">
        <a
          aria-label="Back Home"
          id="faArrowLeft"
          [routerLink]="'/portfolio'"
          fragment="bio-anchor"
          ><fa-icon [icon]="faArrowLeft" size="2x"></fa-icon
        ></a>
        <p>Back<br />Home</p>
      </div>
      <!-- Button #2 -->
      <div class="project-link">
        <a
          aria-label="View Code"
          id="faGlobe"
          href="https://github.com/MechialH/Byte-Me"
          target="_blank"
          ><fa-icon [icon]="faGlobe" size="2x"></fa-icon
        ></a>
        <p>View<br />Code</p>
      </div>
    </div>
  </div>
  <!-- END PROJECT TOP SECTION -->
  <div class="project-pics">
    <div class="project-img">
      <img
        src="/assets/Byte Me/byte-me-desktop.png"
        alt="Byte Me Desktop Screens"
      />
    </div>
    <!-- Brand definitions -->
    <div class="brand-define">
      <!-- Row of Fonts -->
      <div class="definition font-define">
        <div class="font">
          <p class="font-eyebrow">Heading</p>
          <img src="/assets/JPG/zilla.jpg" alt="Zilla Slab Bold" />
        </div>
        <div class="font">
          <p class="font-eyebrow">Body</p>
          <img src="/assets/JPG/red-hat.jpg" alt="Red Hat Display" />
        </div>
      </div>
      <!-- Row of Swatches -->
      <div class="definition">
        <div class="color byte-primary">
          <p class="color-eyebrow">Primary</p>
          <div class="color-definition">
            <p>
              <strong>C</strong>64 <strong>M</strong>00 <strong>Y</strong>74
              <strong>K</strong>00
            </p>
            <p><strong>HEX</strong> #5ABF73</p>
          </div>
        </div>
        <div class="color byte-secondary">
          <p class="color-eyebrow">Secondary</p>
          <div class="color-definition">
            <p>
              <strong>C</strong>00 <strong>M</strong>21 <strong>Y</strong>70
              <strong>K</strong>00
            </p>
            <p><strong>HEX</strong> #FFCC66</p>
          </div>
        </div>
      </div>
    </div>
    <!-- End brand swatches -->
    <div class="project-img byte-icon">
      <img src="/assets/Byte Me/byte-me-icon.png" alt="Byte Me Icon" />
    </div>
  </div>
</section>

<section class="horizontal-scroll" #cirle (mousemove)="onMouseMove(circle)">
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <img
    src="/assets/Byte Me/phones/byte-me-phone-1.png"
    alt="Byte Me Phone Screen"
  />
  <img
    src="/assets/Byte Me/phones/byte-me-phone-2.png"
    alt="Byte Me Phone Screen"
  />
  <img
    src="/assets/Byte Me/phones/byte-me-phone-3.png"
    alt="Byte Me Phone Screen"
  />
  <img
    src="/assets/Byte Me/phones/byte-me-phone-4.png"
    alt="Byte Me Phone Screen"
  />
  <img
    src="/assets/Byte Me/phones/byte-me-phone-5.png"
    alt="Byte Me Phone Screen"
  />
  <img
    src="/assets/Byte Me/phones/byte-me-phone-6.png"
    alt="Byte Me Phone Screen"
  />
  <img
    src="/assets/Byte Me/phones/byte-me-phone-7.png"
    alt="Byte Me Phone Screen"
  />
  <img
    src="/assets/Byte Me/phones/byte-me-phone-8.png"
    alt="Byte Me Phone Screen"
  />
  <img
    src="/assets/Byte Me/phones/byte-me-phone-9.png"
    alt="Byte Me Phone Screen"
  />
</section>

<nav #cirle (mousemove)="onMouseMove(circle)">
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <ul class="nav-links">
    <li>
      <a [routerLink]="'/process'" fragment="bio-anchor"
        ><span class="arrow-left">← </span> <strong>05</strong> The Portfolio
        Process</a
      >
    </li>
    <li>
      <a [routerLink]="'/playground'" fragment="bio-anchor"
        ><strong>07</strong> The Playground
        <span class="arrow-right">→</span></a
      >
    </li>
  </ul>
</nav>

<!-- OLD CODE -->
<!-- 
<div class="project-words-container">
  <div class="project-header">
    <h1>Byte Me</h1>
  </div>
  <div class="project-details">
    <p>
      After talking with food-gurus and at-home-chefs alike, we wanted to be
      certain that this app served as a tool at every step of the cooking
      process. Unlike most popular recipe apps that are over-bloated with blog
      posts or the dull applications that have great resources but a horrible
      user experience, Byte Me thrives in the middle and presents useful tools
      with a modern "refined-palette" aesthetic. <br /><br />The appearance of
      a dish is just as important as the first "byte", so why should that
      standard differ for the app that its served on?
    </p>

    <div class="project-hero-img" id="byte-me-project-hero">
      <img src="assets/PNG/byte-me-hero.png" alt="Byte Me Hero" />
    </div>
  </div>
</div>
Back Button
  <div class="project-buttons">
    <a class="back" [routerLink]="'/portfolio'" aria-label="Back To Portfolio"
      ><fa-icon [icon]="faArrowLeft" size="2x"></fa-icon
    ></a>
    <p>Back</p>
    <a
      class="view-code"
      href="https://github.com/MechialH/Byte-Me"
      target="_blank"
      alt="Github Repo"
      ><fa-icon [icon]="faGlobe" size="2x"></fa-icon
    ></a>
    <p>VIEW CODE</p>

    <div class="project-info">
      <div class="project-tags">
        <div class="tag team"><p>Team Project</p></div>
        <div class="tag grand-circus"><p>Grand Circus</p></div>
      </div>
      <h2>Technologies Used</h2>
      <p>
        Angular 11, HTML, CSS, JavaScript (ES6), Edamam API, Spoonacular API,
        Convert-unit API, FireBase API, Sentry.io API
      </p>
      <h2>Contributors</h2>
      <p>Paige Blakeslee, Mechial Harrison, Maggie Oudsema.</p>
    </div>
  </div>
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
</section>
-->
