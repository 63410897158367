<app-navigation></app-navigation>

<section class="project-parent" #cirle (mousemove)="onMouseMove(circle)">
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <!-- Left side of project content -->
  <div class="project-info">
    <div class="project-bio">
      <p class="counter"><strong>07/</strong>09</p>
      <p class="project-headline">
        <strong>The Playground —</strong>A scattered space of half-finished
        projects, random ideas, experiments and rejected work...
      </p>
    </div>
    <!-- Right side of project content -->
    <div class="project-links">
      <!-- Button #1 -->
      <div class="project-link">
        <a
          aria-label="Back Home"
          id="faArrowLeft"
          [routerLink]="'/portfolio'"
          fragment="bio-anchor"
          ><fa-icon [icon]="faArrowLeft" size="2x"></fa-icon
        ></a>
        <p>Back<br />Home</p>
      </div>
    </div>
  </div>
</section>

<section class="horizontal-scroll" #cirle (mousemove)="onMouseMove(circle)">
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <img src="/assets/playground-uploads/Project-0.jpg" alt="PEB Logo" />
  <img src="/assets/playground-uploads/Project-1.jpg" alt="Planet Jeep" />
  <img
    src="/assets/playground-uploads/Project-2.jpg"
    alt="Squid Illustration"
  />
  <img src="/assets/playground-uploads/Project-3.jpg" alt="Thornapple" />
  <img src="/assets/playground-uploads/Project-4.jpg" alt="Cat and Dog Logo" />
  <img
    src="/assets/playground-uploads/Project-5.jpg"
    alt="Crazy Pet Owner Business Card"
  />
  <img src="/assets/playground-uploads/Project-6.jpg" alt="Ground Coffee" />
  <img src="/assets/playground-uploads/Project-7.jpg" alt="3 Oil Bottles" />
  <img src="/assets/playground-uploads/Project-8.jpg" alt="5 Oil Bottles" />
  <img src="/assets/playground-uploads/Project-9.jpg" alt="Oil Bottle Label" />
  <img
    src="/assets/playground-uploads/Project-12.jpg"
    alt="Mint Hair Crafting"
  />
  <img
    src="/assets/playground-uploads/Project-10.jpg"
    alt="Heather Schramm Logo"
  />
  <img
    src="/assets/playground-uploads/Project-11.jpg"
    alt="Heather Schramm Logo"
  />
</section>

<nav class="footer-links" #cirle (mousemove)="onMouseMove(circle)">
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <ul class="nav-links">
    <li>
      <a [routerLink]="'/byte-me'" fragment="bio-anchor"
        ><span class="arrow-left">← </span> <strong>06</strong> Byte Me</a
      >
    </li>
    <li>
      <a [routerLink]="'/grlpwr'" fragment="bio-anchor"
        ><strong>08</strong> GRL PWR <span class="arrow-right">→</span></a
      >
    </li>
  </ul>
</nav>

<!-- OLD CODE -->
<!-- 
<div class="project-words-container">
  <div class="project-header">
    <h1>Byte Me</h1>
  </div>
  <div class="project-details">
    <p>
      After talking with food-gurus and at-home-chefs alike, we wanted to be
      certain that this app served as a tool at every step of the cooking
      process. Unlike most popular recipe apps that are over-bloated with blog
      posts or the dull applications that have great resources but a horrible
      user experience, Byte Me thrives in the middle and presents useful tools
      with a modern "refined-palette" aesthetic. <br /><br />The appearance of
      a dish is just as important as the first "byte", so why should that
      standard differ for the app that its served on?
    </p>

    <div class="project-hero-img" id="byte-me-project-hero">
      <img src="assets/PNG/byte-me-hero.png" alt="Byte Me Hero" />
    </div>
  </div>
</div>
Back Button
  <div class="project-buttons">
    <a class="back" [routerLink]="'/portfolio'" aria-label="Back To Portfolio"
      ><fa-icon [icon]="faArrowLeft" size="2x"></fa-icon
    ></a>
    <p>Back</p>
    <a
      class="view-code"
      href="https://github.com/MechialH/Byte-Me"
      target="_blank"
      alt="Github Repo"
      ><fa-icon [icon]="faGlobe" size="2x"></fa-icon
    ></a>
    <p>VIEW CODE</p>

    <div class="project-info">
      <div class="project-tags">
        <div class="tag team"><p>Team Project</p></div>
        <div class="tag grand-circus"><p>Grand Circus</p></div>
      </div>
      <h2>Technologies Used</h2>
      <p>
        Angular 11, HTML, CSS, JavaScript (ES6), Edamam API, Spoonacular API,
        Convert-unit API, FireBase API, Sentry.io API
      </p>
      <h2>Contributors</h2>
      <p>Paige Blakeslee, Mechial Harrison, Maggie Oudsema.</p>
    </div>
  </div>
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
</section>
-->

<!-- OLD CODE -->

<!-- <app-navigation></app-navigation>

<section
  class="playground-parent"
  id="playground-anchor"
  #cirle
  (mousemove)="onMouseMove(circle)"
>
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div> -->
<!-- Left side of bio content -->
<!-- <div class="playground-words-container">
    <div class="playground-header">
      <h1>The Playground</h1>
    </div>
    <div class="playground-paragraph">
      <p>
        A scattered space of half-finished projects, random ideas, experiments
        and rejected work ...
      </p>
    </div>
  </div> -->
<!-- Back Button -->
<!-- <div class="back">
    <a [routerLink]="'/portfolio'" aria-label="Back To Portfolio"
      ><fa-icon [icon]="faArrowLeft" size="2x"></fa-icon
    ></a>
    <p>Back</p>
  </div>
</section>

<div class="pg-upload-parent" #circle (mousemove)="onMouseMove(circle)">
  <app-pg-upload
    *ngFor="let pgUpload of pgUploads"
    [pgUpload]="pgUpload"
  ></app-pg-upload>
  <div class="zoom" [style.top.px]="y" [style.left.px]="x">
    <fa-icon [icon]="faPlus"></fa-icon>
  </div>
</div> -->
