import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { faArrowLeft, faPlayCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-secrets',
  templateUrl: './secrets.component.html',
  styleUrls: ['./secrets.component.css'],
})
export class SecretsComponent implements OnInit {
  faArrowLeft = faArrowLeft;
  playCircle = faPlayCircle;

  x = 0;
  y = 0;

  @ViewChild('circle') circle: any;

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: any) {
    this.x = e.pageX - 35;
    this.y = e.pageY - 35;
    console.log(this.x, this.y);
  }

  constructor() {}

  ngOnInit(): void {}
}
