<app-navigation></app-navigation>

<section class="project-parent" #cirle (mousemove)="onMouseMove(circle)">
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <!-- Left side of project content -->
  <div class="project-info">
    <div class="project-bio">
      <p class="counter"><strong>02/</strong>09</p>
      <p class="project-headline">
        <strong>DodgeGarage —</strong> A modernized homepage for the enthusiast
        site
      </p>
      <div class="project-hero">
        <img src="/assets/PNG/DG-homepage-hero.png" alt="DodgeGarage Hero" />
      </div>
      <div class="project-meta">
        <p><strong>PROJECT:</strong> Professional</p>
        <p><strong>ROLE:</strong> Ui/Ux</p>
        <p><strong>DATE:</strong> 2022</p>
      </div>
      <p class="project-copy">
        Dodge has been unmatched in their embodiment of irreverence, and a lot
        of their grit is best represented by their community of Dodge owners and
        drivers. DodgdeGarage was created as a space for those enthusiasts to be
        celebrated, while also granting acess all things Dodge. With so many
        moving parts and constant updates, I was given the challenge to create a
        homepage that had to be as dynamic and fast-paced as the Dodge vehicles
        themselves.
      </p>
    </div>
    <!-- Right side of project content -->
    <div class="project-links">
      <!-- Button #1 -->
      <div class="project-link">
        <a
          aria-label="Back Home"
          id="faArrowLeft"
          [routerLink]="'/portfolio'"
          fragment="bio-anchor"
          ><fa-icon [icon]="faArrowLeft" size="2x"></fa-icon
        ></a>
        <p>Back<br />Home</p>
      </div>
      <!-- Button #2 -->
      <div class="project-link">
        <a
          aria-label="View Code"
          id="faGlobe"
          href="https://www.dodgegarage.com/"
          target="_blank"
          ><fa-icon [icon]="faGlobe" size="2x"></fa-icon
        ></a>
        <p>View Live<br />Site</p>
      </div>
    </div>
  </div>
  <!-- END PROJECT TOP SECTION -->
  <div class="project-pics">
    <div class="project-img">
      <img
        src="/assets/DodgeGarage/iphones-mockup-2.png"
        alt="DodgeGarage Mobile Screens"
      />
    </div>
    <!-- Brand definitions -->
    <div class="brand-define">
      <!-- Row of Fonts -->
      <div class="definition font-define">
        <div class="font">
          <p class="font-eyebrow">Heading</p>
          <img src="/assets/JPG/barlow.jpg" alt="Barlow Condensed" />
        </div>
        <div class="font">
          <p class="font-eyebrow">Body</p>
          <img src="/assets/JPG/roboto.jpg" alt="Roboto" />
        </div>
      </div>
      <!-- Row of Swatches -->
      <div class="definition">
        <div class="color dg-primary">
          <p class="color-eyebrow">Primary</p>
          <div class="color-definition">
            <p>
              <strong>C</strong>07 <strong>M</strong>100 <strong>Y</strong>100
              <strong>K</strong>01
            </p>
            <p><strong>HEX</strong> #DC0000</p>
          </div>
        </div>
        <div class="color dg-secondary">
          <p class="color-eyebrow">Secondary</p>
          <div class="color-definition">
            <p>
              <strong>C</strong>52 <strong>M</strong>43 <strong>Y</strong>43
              <strong>K</strong>08
            </p>
            <p><strong>HEX</strong> #7F7F7F</p>
          </div>
        </div>
      </div>
    </div>

    <!-- COMPS -->
    <div class="two-project-img">
      <div class="wireframe mockup">
        <p>Original Wireframe</p>
        <div class="hairline"></div>
        <img src="/assets/DodgeGarage/wireframe.jpg" alt="Original Wireframe" />
      </div>
      <div class="wireframe mockup">
        <p><strong>High-Fidelity Mockup</strong></p>
        <div class="hairline"></div>
        <img
          src="/assets/DodgeGarage/dg-mockup.png"
          alt="High Fidelity Mockup"
        />
      </div>
    </div>

    <!-- End of project images -->
  </div>
</section>

<nav #cirle (mousemove)="onMouseMove(circle)">
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <ul class="nav-links">
    <li>
      <a [routerLink]="'/mopar'" fragment="bio-anchor"
        ><span class="arrow-left">← </span> <strong>01</strong> Mopar</a
      >
    </li>
    <li>
      <a [routerLink]="'/luxwall'" fragment="bio-anchor"
        ><strong>03</strong> LUXWALL <span class="arrow-right">→</span></a
      >
    </li>
  </ul>
</nav>

<!-- OLD CODE -->
<!-- 
<div class="project-words-container">
  <div class="project-header">
    <h1>Byte Me</h1>
  </div>
  <div class="project-details">
    <p>
      After talking with food-gurus and at-home-chefs alike, we wanted to be
      certain that this app served as a tool at every step of the cooking
      process. Unlike most popular recipe apps that are over-bloated with blog
      posts or the dull applications that have great resources but a horrible
      user experience, Byte Me thrives in the middle and presents useful tools
      with a modern "refined-palette" aesthetic. <br /><br />The appearance of
      a dish is just as important as the first "byte", so why should that
      standard differ for the app that its served on?
    </p>

    <div class="project-hero-img" id="byte-me-project-hero">
      <img src="assets/PNG/byte-me-hero.png" alt="Byte Me Hero" />
    </div>
  </div>
</div>
Back Button
  <div class="project-buttons">
    <a class="back" [routerLink]="'/portfolio'" aria-label="Back To Portfolio"
      ><fa-icon [icon]="faArrowLeft" size="2x"></fa-icon
    ></a>
    <p>Back</p>
    <a
      class="view-code"
      href="https://github.com/MechialH/Byte-Me"
      target="_blank"
      alt="Github Repo"
      ><fa-icon [icon]="faGlobe" size="2x"></fa-icon
    ></a>
    <p>VIEW CODE</p>

    <div class="project-info">
      <div class="project-tags">
        <div class="tag team"><p>Team Project</p></div>
        <div class="tag grand-circus"><p>Grand Circus</p></div>
      </div>
      <h2>Technologies Used</h2>
      <p>
        Angular 11, HTML, CSS, JavaScript (ES6), Edamam API, Spoonacular API,
        Convert-unit API, FireBase API, Sentry.io API
      </p>
      <h2>Contributors</h2>
      <p>Paige Blakeslee, Mechial Harrison, Maggie Oudsema.</p>
    </div>
  </div>
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
</section>
-->
