import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { AccessibilityComponent } from './project-pages/accessibility/accessibility.component';
import { ByteMeComponent } from './project-pages/byte-me/byte-me.component';
import { DodgegarageComponent } from './project-pages/dodgegarage/dodgegarage.component';
import { GcConnectComponent } from './project-pages/gc-connect/gc-connect.component';
import { GifsComponent } from './project-pages/gifs/gifs.component';
import { GrlpwrComponent } from './project-pages/grlpwr/grlpwr.component';
import { HohoComponent } from './project-pages/hoho/hoho.component';
import { LuxwallComponent } from './project-pages/luxwall/luxwall.component';
import { MdsComponent } from './project-pages/mds/mds.component';
import { MoparComponent } from './project-pages/mopar/mopar.component';
import { NinComponent } from './project-pages/nin/nin.component';
import { PlaygroundComponent } from './project-pages/playground/playground.component';
import { ProcessComponent } from './project-pages/process/process.component';
import { SecretsComponent } from './secrets/secrets.component';

const routes: Routes = [
  { path: 'portfolio', component: PortfolioComponent },
  { path: 'accessibility', component: AccessibilityComponent },
  { path: 'byte-me', component: ByteMeComponent },
  { path: 'gc-connect', component: GcConnectComponent },
  { path: 'hoho', component: HohoComponent },
  { path: 'mds', component: MdsComponent },
  { path: 'mopar', component: MoparComponent },
  { path: 'nin', component: NinComponent },
  { path: 'playground', component: PlaygroundComponent },
  { path: 'secrets', component: SecretsComponent },
  { path: 'dodgegarage', component: DodgegarageComponent },
  { path: 'gifs', component: GifsComponent },
  { path: 'grlpwr', component: GrlpwrComponent },
  { path: 'luxwall', component: LuxwallComponent },
  { path: 'process', component: ProcessComponent },
  { path: '', redirectTo: '/portfolio', pathMatch: 'full' },
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 0],
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
