<app-dark-nav></app-dark-nav>

<section class="gif-parent">
  <div class="project-info">
    <div class="project-bio">
      <p class="counter"><strong>04/</strong>09</p>
      <p class="project-headline">
        <strong>Animated GIFs —</strong> A collection of animated Social Media
        stickers
      </p>
      <div class="project-meta">
        <p><strong>PROJECT:</strong> Freelance</p>
        <p><strong>ROLE:</strong> Art Direction & Animation</p>
        <p><strong>DATE:</strong> 2021 - 2022</p>
      </div>
      <p class="project-copy">
        Using memes and GIFs for marketing has become a new norm in our current
        digital era. With the rising popularity of integrating social media
        marketing organically on individuals' feeds, brands have started to seek
        new ways of personalizing their messaging. These animated stickers were
        created to be used by brands and users on TikTok, Instagram, and any
        other social app that has GIPHY integration.
      </p>
    </div>
    <!-- Right side of project content -->
    <div class="project-links">
      <!-- Button #1 -->
      <div class="project-link">
        <a
          aria-label="Back Home"
          id="faArrowLeft"
          [routerLink]="'/portfolio'"
          fragment="bio-anchor"
          ><fa-icon [icon]="faArrowLeft" size="2x"></fa-icon
        ></a>
        <p>Back<br />Home</p>
      </div>
      <!-- Button #2 -->
      <div class="project-link">
        <a
          aria-label="View Code"
          id="faGlobe"
          href="https://giphy.com/channel/paigeblakeslee"
          target="_blank"
          ><fa-icon [icon]="faGlobe" size="2x"></fa-icon
        ></a>
        <p>View on<br />Giphy</p>
      </div>
    </div>
  </div>
  <!-- END OF TOP SECTION -->
  <div class="gif-collection">
    <!-- GIF #1 -->
    <div class="gif">
      <img
        class="static"
        src="/assets/Previews/4-stickers.png"
        alt="SMPLFD stickers"
      />
      <img
        class="active"
        src="/assets/GIFs/4-stickers.gif"
        alt="SMPLFD stickers"
      />
    </div>
    <!-- END GIF #1 -->
    <!-- GIF #2 -->
    <div class="gif">
      <img
        class="static"
        src="/assets/Previews/island-view.png"
        alt="Island View GIF"
      />
      <img
        class="active"
        src="/assets/GIFs/island-view.gif"
        alt="Island View GIF"
      />
    </div>
    <!-- END GIF #2 -->
    <!-- GIF #3 -->
    <div class="gif">
      <img
        class="static"
        src="/assets/Previews/screenprint.png"
        alt="screenprint gif"
      />
      <img
        class="active"
        src="/assets/GIFs/screenprint.gif"
        alt="screenprint gif"
      />
    </div>
    <!-- END GIF #3 -->
    <!-- GIF #4 -->
    <div class="gif liquid">
      <img
        class="static"
        src="/assets/Previews/smplfd-liquid.png"
        alt="SMPLFD Logo"
      />
      <img
        class="active"
        src="/assets/GIFs/smplfd-liquid.gif"
        alt="SMPLFD Logo"
      />
    </div>
    <!-- END GIF #4 -->
    <!-- GIF #5 -->
    <div class="gif">
      <img
        class="static"
        src="/assets/Previews/neon-green.png"
        alt="Open 24 Hrs SMPLFD.com"
      />
      <img
        class="active"
        src="/assets/GIFs/neon-green.gif"
        alt="Open 24 Hrs SMPLFD.com"
      />
    </div>
    <!-- END GIF #5 -->

    <!-- GIF #6 -->
    <div class="gif">
      <img
        class="static"
        src="/assets/Previews/smplfd.png"
        alt="SMPLFD stickers"
      />
      <img class="active" src="/assets/GIFs/smplfd.gif" alt="SMPLFD stickers" />
    </div>
    <!-- END GIF #6 -->
    <!-- GIF #7 -->
    <div class="gif">
      <img
        class="static"
        src="/assets/Previews/hank-gif.png"
        alt="Nurses Inspire Nurses Van"
      />
      <img
        class="active"
        src="/assets/GIFs/hank-gif.gif"
        alt="Nurses Inspire Nurses Van"
      />
    </div>
    <!-- END GIF #7 -->
    <!-- GIF #8 -->
    <div class="gif">
      <img
        class="static"
        src="/assets/Previews/kevy.png"
        alt="French Bulldog"
      />
      <img class="active" src="/assets/GIFs/kevy.gif" alt="French Bulldog" />
    </div>
    <!-- END GIF #8 -->
    <!-- GIF #9 -->
    <div class="gif">
      <img
        class="static"
        src="/assets/Previews/lfg.png"
        alt="Let's Fuggin' Go!"
      />
      <img class="active" src="/assets/GIFs/lfg.gif" alt="Let's Fuggin' Go!" />
    </div>
    <!-- END GIF #9 -->
    <!-- GIF #10 -->
    <div class="gif">
      <img
        class="static"
        src="/assets/Previews/24LUXE-Mani.png"
        alt="Manicure"
      />
      <img class="active" src="/assets/GIFs/24LUXE-Mani.gif" alt="Manicure" />
    </div>
    <!-- END GIF #10 -->
    <!-- GIF #11 -->
    <div class="gif">
      <img
        class="static"
        src="/assets/Previews/24LUXE-Hydrate.png"
        alt="Hydrate Your Selfie"
      />
      <img
        class="active"
        src="/assets/GIFs/24LUXE-Hydrate.gif"
        alt="Hydrate Your Selfie"
      />
    </div>
    <!-- END GIF #11 -->
    <!-- GIF #12 -->
    <div class="gif">
      <img
        class="static"
        src="/assets/Previews/24LUXE-Brows.png"
        alt="Brows on Point"
      />
      <img
        class="active"
        src="/assets/GIFs/24LUXE-Brows.gif"
        alt="Brows on Point"
      />
    </div>
    <!-- END GIF #12 -->
    <!-- GIF #13 -->
    <div class="gif">
      <img
        class="static"
        src="/assets/Previews/bison-tough-3d.png"
        alt="BISON TOUGH"
      />
      <img
        class="active"
        src="/assets/GIFs/bison-tough-3d.gif"
        alt="BISON TOUGH"
      />
    </div>
    <!-- END GIF #13 -->
    <!-- GIF #14 -->
    <div class="gif">
      <img
        class="static"
        src="/assets/Previews/gfbs-white-2.png"
        alt="Go Fast. Be Safe."
      />
      <img
        class="active"
        src="/assets/GIFs/gfbs-white-2.gif"
        alt="Go Fast. Be Safe."
      />
    </div>
    <!-- END GIF #14 -->

    <!-- GIF #15 -->
    <div class="gif">
      <img class="static" src="/assets/Previews/the-herd.png" alt="The Herd" />
      <img class="active" src="/assets/GIFs/the-herd.gif" alt="The Herd" />
    </div>
    <!-- END GIF #15 -->
  </div>
</section>

<nav>
  <ul class="nav-links">
    <li>
      <a [routerLink]="'/luxwall'" fragment="bio-anchor"
        ><span class="arrow-left">← </span> <strong>03</strong> LUXWALL</a
      >
    </li>
    <li>
      <a [routerLink]="'/process'" fragment="bio-anchor"
        ><strong>05</strong> The Portfolio Process
        <span class="arrow-right">→</span></a
      >
    </li>
  </ul>
</nav>
