import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faArrowUp, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.css'],
})
export class ResumeComponent implements OnInit {
  faFilePdf = faFilePdf;
  faPlus = faPlus;
  faArrowUp = faArrowUp;
  isShownOne = false;
  isShownTwo = false;
  isShownThree = false;
  isShownFour = false;
  isShownFive = false;

  x = 0;
  y = 0;

  @ViewChild('circle') circle: any;

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: any) {
    this.x = e.pageX - 30;
    this.y = e.pageY - 30;
    console.log(this.x, this.y);
  }

  constructor() {}

  ngOnInit(): void {}

  toggleShowOne() {
    this.isShownOne = !this.isShownOne;
  }

  toggleShowTwo() {
    this.isShownTwo = !this.isShownTwo;
  }

  toggleShowThree() {
    this.isShownThree = !this.isShownThree;
  }

  toggleShowFour() {
    this.isShownFour = !this.isShownFour;
  }

  toggleShowFive() {
    this.isShownFive = !this.isShownFive;
  }
}
