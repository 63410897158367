<app-navigation></app-navigation>

<section class="project-parent" #cirle (mousemove)="onMouseMove(circle)">
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <!-- Left side of project content -->
  <div class="project-info">
    <div class="project-bio">
      <p class="counter"><strong>03/</strong>09</p>
      <p class="project-headline">
        <strong>LUXWALL —</strong> Establishing a visual direction for a
        powerful start-up
      </p>
      <div class="project-hero">
        <img src="/assets/PNG/luxwall-hero.png" alt="LuxWall Hero" />
      </div>
      <div class="project-meta">
        <p><strong>PROJECT:</strong> Professional</p>
        <p><strong>ROLE:</strong> Art Direction</p>
        <p><strong>DATE:</strong> 2022</p>
      </div>
      <p class="project-copy">
        LUXWALL is a brand new player in the windows & glass space. But after
        unlocking the key to reducing carbon emissions in buildings and
        structures, the start-up has taken off in their race to collect
        investors. With a product so revolutionary, a visual direction had to be
        established that embodied LUXWALL's values of sustainability while still
        matching their sophisticated technology.
      </p>
    </div>
    <!-- Right side of project content -->
    <div class="project-links">
      <!-- Button #1 -->
      <div class="project-link">
        <a
          aria-label="Back Home"
          id="faArrowLeft"
          [routerLink]="'/portfolio'"
          fragment="bio-anchor"
          ><fa-icon [icon]="faArrowLeft" size="2x"></fa-icon
        ></a>
        <p>Back<br />Home</p>
      </div>
      <!-- Button #2 -->
      <!-- <div class="project-link">
        <a
          aria-label="View Code"
          id="faGlobe"
          href="https://github.com/MechialH/Byte-Me"
          target="_blank"
          ><fa-icon [icon]="faGlobe" size="2x"></fa-icon
        ></a>
        <p>View<br />Code</p>
      </div> -->
    </div>
  </div>
  <!-- END PROJECT TOP SECTION -->
  <div class="project-pics">
    <div class="two-project-img">
      <img
        src="/assets/Luxwall/business-card-back.png"
        alt="LuxWall Business Card Front"
      />
      <img
        src="/assets/Luxwall/business-card-front.png"
        alt="LuxWall Business Card Back"
      />
    </div>
    <!-- Brand definitions -->
    <div class="brand-define">
      <!-- Row of Fonts -->
      <div class="definition font-define">
        <div class="font">
          <p class="font-eyebrow">Heading</p>
          <img src="/assets/JPG/termina.jpg" alt="Termina Demi" />
        </div>
        <div class="font">
          <p class="font-eyebrow">Body</p>
          <img
            class="font-larger"
            src="/assets/JPG/questrial.jpg"
            alt="Questrial Regular"
          />
        </div>
      </div>
      <!-- Row of Swatches -->
      <div class="definition">
        <div class="color lux-primary">
          <p class="color-eyebrow">Primary</p>
          <div class="color-definition">
            <p>
              <strong>C</strong>65 <strong>M</strong>49 <strong>Y</strong>33
              <strong>K</strong>07
            </p>
            <p><strong>HEX</strong> #65748A</p>
          </div>
        </div>
        <div class="color lux-secondary">
          <p class="color-eyebrow">Secondary</p>
          <div class="color-definition">
            <p>
              <strong>C</strong>39 <strong>M</strong>21 <strong>Y</strong>14
              <strong>K</strong>00
            </p>
            <p><strong>HEX</strong> #9DB3C6</p>
          </div>
        </div>
        <div class="color lux-tertiary">
          <p class="color-eyebrow">Secondary</p>
          <div class="color-definition">
            <p>
              <strong>C</strong>00 <strong>M</strong>46 <strong>Y</strong>88
              <strong>K</strong>00
            </p>
            <p><strong>HEX</strong> #FF9D33</p>
          </div>
        </div>
      </div>
    </div>
    <!-- End brand swatches -->
    <div class="lux-icon">
      <img src="/assets/Luxwall/luxwall-logo.png" alt="Luxwall Icon" />
    </div>

    <div class="project-img lux-small-img">
      <img
        src="/assets/Luxwall/Luxwall-Macbook-Pro-2017.png"
        alt="LuxWall Website"
      />
    </div>
    <div class="project-img">
      <img
        src="/assets/Luxwall/presentation/presentation.png"
        alt="LuxWall Corporate Template"
      />
    </div>
    <!-- END PROJECTS -->
  </div>
</section>

<nav #cirle (mousemove)="onMouseMove(circle)">
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <ul class="nav-links">
    <li>
      <a [routerLink]="'/dodgegarage'" fragment="bio-anchor"
        ><span class="arrow-left">← </span> <strong>02</strong> DodgeGarage</a
      >
    </li>
    <li>
      <a [routerLink]="'/gifs'" fragment="bio-anchor"
        ><strong>04</strong> Animated GIFs <span class="arrow-right">→</span></a
      >
    </li>
  </ul>
</nav>
