<section
  class="resume-parent"
  id="resume-anchor"
  #circle
  (mousemove)="onMouseMove(circle)"
>
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <div class="resume-description">
    <div class="resume-header">
      <h1>Designer, Developer, Daydream Believer</h1>
    </div>
    <!-- Parent of all entries -->
    <div class="resume-experience">
      <!-- ART DIRECTOR -->
      <div
        class="resume-experience-child"
        (click)="toggleShowOne()"
        id="JRTone"
      >
        <!-- Job entry collapsed -->
        <div class="collapsed">
          <div class="job-title">
            <h2>Digital Designer / Art Director</h2>
            <p>The JRT Agency</p>
          </div>
          <div class="job-date-expand">
            <p>Mar 2020 - present</p>
            <button aria-label="Expand" class="expand-button">
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </div>
        </div>
        <!-- This content will expand -->
        <div class="expanded" *ngIf="isShownOne">
          <ul>
            <li>
              Lead Art Director designing user-first content for the Mopar
              account and as a back up when needed for Mopar Canada, Idemitsu
              Lubricants, and Stellantis (FCA) accounts.
            </li>
            <li>
              Meeting hot project deadlines while juggling multiple
              projects/clients and upholding the highest standards of quality
            </li>
            <li>
              Design and wireframe interactive digital content for OLA’s,
              responsive digital interfaces, and email camplaigns
            </li>
            <li>
              Established a new design system using Atomic Design principles as
              lead UI/ UX designer on redesign project for website with 1500+
              pages
            </li>
          </ul>
        </div>
      </div>
      <!-- End of ART DIRECTOR -->

      <!-- JR ART DIRECTOR -->
      <div
        class="resume-experience-child"
        (click)="toggleShowTwo()"
        id="JRTtwo"
      >
        <!-- Job entry collapsed -->
        <div class="collapsed">
          <div class="job-title">
            <h2>Junior Art Director</h2>
            <p>The JRT Agency</p>
          </div>
          <div class="job-date-expand">
            <p>May 2018 - Feb 2020</p>
            <button aria-label="Expand" class="expand-button">
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </div>
        </div>
        <!-- This content will expand -->
        <div class="expanded" *ngIf="isShownTwo">
          <ul>
            <li>
              Worked directly with a copywriter, client representatives, and
              creative director to present creative solutions in-line with
              agency and client standards
            </li>
            <li>
              Conceptualized independently and in teams to create original
              campaigns for clients
            </li>
            <li>
              Redesigned old promotional materials to make them consistent and
              in-line with modern design trends
            </li>
          </ul>
        </div>
      </div>
      <!-- End of JR ART DIRECTOR -->

      <!-- DESIGN INTERN -->
      <div
        class="resume-experience-child"
        (click)="toggleShowThree()"
        id="JRTthree"
      >
        <!-- Job entry collapsed -->
        <div class="collapsed">
          <div class="job-title">
            <h2>Design Intern</h2>
            <p>The JRT Agency</p>
          </div>
          <div class="job-date-expand">
            <p>July 2017 - May 2018</p>
            <button aria-label="Expand" class="expand-button">
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </div>
        </div>
        <!-- This content will expand -->
        <div class="expanded" *ngIf="isShownThree">
          <ul>
            <li>
              Production work, prepping files for print or working off-location
              for pre-press checks for large print pieces
            </li>
          </ul>
        </div>
      </div>
      <!-- End of DESIGN INTERN -->

      <!-- GRAPHIC DESIGN COORDINATOR -->
      <div class="resume-experience-child" (click)="toggleShowFour()" id="CE">
        <!-- Job entry collapsed -->
        <div class="collapsed">
          <div class="job-title">
            <h2>Graphic Design Coordinator</h2>
            <p>Cause & Effect Marketing</p>
          </div>
          <div class="job-date-expand">
            <p>June 2015 - July 2017</p>
            <button aria-label="Expand" class="expand-button">
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </div>
        </div>
        <!-- This content will expand -->
        <div class="expanded" *ngIf="isShownFour">
          <ul>
            <li>
              Print/digital layouts for promotional flyers, invitations,
              e-blasts for non-profits and small businesses
            </li>
            <li>
              Original logo designs and brand style guide creation for start-ups
            </li>
            <li>In-person event photography</li>
            <li>Social media content creation and administration</li>
          </ul>
        </div>
      </div>
      <!-- End of GRAPHIC DESIGN COORDINATOR -->

      <!-- ART DIRECTOR -->
      <div class="resume-experience-child" (click)="toggleShowFive()" id="NET">
        <!-- Job entry collapsed -->
        <div class="collapsed">
          <div class="job-title">
            <h2>Marketing Assistant / Junior Graphic Designer</h2>
            <p>123Net</p>
          </div>
          <div class="job-date-expand">
            <p>June 2015 - June 2017</p>
            <button aria-label="Expand" class="expand-button">
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </div>
        </div>
        <!-- This content will expand -->
        <div class="expanded" *ngIf="isShownFive">
          <ul>
            <li>Research of competitors and performance metrics</li>
            <li>
              Web design using WordPress and layout design for product and
              service advertisements
            </li>
            <li>Mechanical drafting pathways for fiber network</li>
            <li>Photography and design for customer user guides</li>
          </ul>
        </div>
      </div>
      <!-- End of ART DIRECTOR -->
    </div>
    <!-- End of Entries -->
  </div>
  <div class="resume-links-container">
    <!-- Dribbble -->
    <a
      aria-label="Download PDF"
      href="assets/PDF/Paige_Blakeslee_Resume.pdf"
      id="faFilePdf"
      download=""
      ><fa-icon [icon]="faFilePdf" size="2x"></fa-icon
    ></a>
    <p>PDF</p>
    <a
      aria-label="Back To Top"
      id="faArrowUp"
      [routerLink]="'/portfolio'"
      fragment="bio-anchor"
      ><fa-icon [icon]="faArrowUp" size="2x"></fa-icon
    ></a>
    <p>Back To Top</p>
  </div>
</section>
