import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { BioComponent } from './bio/bio.component';
import { WorkComponent } from './work/work.component';
import { ResumeComponent } from './resume/resume.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PlaygroundComponent } from './project-pages/playground/playground.component';
import { HohoComponent } from './project-pages/hoho/hoho.component';
import { GcConnectComponent } from './project-pages/gc-connect/gc-connect.component';
import { MoparComponent } from './project-pages/mopar/mopar.component';
import { NinComponent } from './project-pages/nin/nin.component';
import { MdsComponent } from './project-pages/mds/mds.component';
import { ByteMeComponent } from './project-pages/byte-me/byte-me.component';
import { AccessibilityComponent } from './project-pages/accessibility/accessibility.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { PgUploadComponent } from './project-pages/playground/pg-upload/pg-upload.component';
import { SecretsComponent } from './secrets/secrets.component';
import { DodgegarageComponent } from './project-pages/dodgegarage/dodgegarage.component';
import { GifsComponent } from './project-pages/gifs/gifs.component';
import { LightboxModule } from 'ngx-lightbox';
import { ImageModalComponent } from './project-pages/playground/image-modal/image-modal.component';
import { DarkNavComponent } from './dark-nav/dark-nav.component';
import { AboutComponent } from './about/about.component';
import { SkillsComponent } from './skills/skills.component';
import { LuxwallComponent } from './project-pages/luxwall/luxwall.component';
import { GrlpwrComponent } from './project-pages/grlpwr/grlpwr.component';
import { ProcessComponent } from './project-pages/process/process.component';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    BioComponent,
    WorkComponent,
    ResumeComponent,
    PlaygroundComponent,
    HohoComponent,
    GcConnectComponent,
    MoparComponent,
    NinComponent,
    MdsComponent,
    ByteMeComponent,
    AccessibilityComponent,
    PortfolioComponent,
    PgUploadComponent,
    SecretsComponent,
    DodgegarageComponent,
    GifsComponent,
    ImageModalComponent,
    DarkNavComponent,
    AboutComponent,
    SkillsComponent,
    LuxwallComponent,
    GrlpwrComponent,
    ProcessComponent,
  ],
  entryComponents: [ImageModalComponent],
  imports: [BrowserModule, AppRoutingModule, FontAwesomeModule, LightboxModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
