import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import {
  faDribbble,
  faGithub,
  faLinkedin,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-bio',
  templateUrl: './bio.component.html',
  styleUrls: ['./bio.component.css'],
})
export class BioComponent implements OnInit {
  faDribbble = faDribbble;
  faGithub = faGithub;
  faLinkedin = faLinkedinIn;
  faEnvelope = faEnvelope;
  faPlus = faPlus;
  x = 0;
  y = 0;

  @ViewChild('circle') circle: any;

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: any) {
    this.x = e.pageX - 30;
    this.y = e.pageY - 30;
    console.log(this.x, this.y);
  }
  constructor() {}

  ngOnInit(): void {}
}
