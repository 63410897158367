import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.css'],
})
export class WorkComponent implements OnInit { 
  x = 0;
  y = 0;
  faArrowRight = faArrowRight;

  @ViewChild('circle') circle: any;

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: any) {
    this.x = e.pageX - 30;
    this.y = e.pageY - 30;
    console.log(this.x, this.y);
  }
  
  constructor() {}

  ngOnInit(): void {}
}
