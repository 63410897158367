<app-navigation></app-navigation>

<section class="project-parent" #cirle (mousemove)="onMouseMove(circle)">
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <!-- Left side of project content -->
  <div class="project-info">
    <div class="project-bio">
      <p class="counter"><strong>01/</strong>09</p>
      <p class="project-headline">
        <strong>Mopar —</strong> Reimagining the automotive brand's print &
        digital spaces
      </p>
      <div class="project-hero">
        <img src="/assets/PNG/magazine.png" alt="Mopar Hero" />
      </div>
      <div class="project-meta">
        <p><strong>PROJECT:</strong> Professional</p>
        <p><strong>ROLE:</strong> Ui/Ux & Art Direction</p>
        <p><strong>DATE:</strong> 2019 - present</p>
      </div>
      <p class="project-copy">
        The Mopar brand has been an notable name in the automotive space for
        generations, but the Stellantis (FCA) parts manufacturer has struggled
        to establish themselves independently. Modernizing the brand assets was
        the first step in identifying Mopar as a key player. After years of
        working with brand standards, I was able to implement a new design
        system in their digital space and lead the redesign of their biggest
        asset - the 1500+ page Mopar Dealer Site.
      </p>
    </div>
    <!-- Right side of project content -->
    <div class="project-links">
      <!-- Button #1 -->
      <div class="project-link">
        <a
          aria-label="Back Home"
          id="faArrowLeft"
          [routerLink]="'/portfolio'"
          fragment="bio-anchor"
          ><fa-icon [icon]="faArrowLeft" size="2x"></fa-icon
        ></a>
        <p>Back<br />Home</p>
      </div>
      <!-- Button #2 -->
      <!-- <div class="project-link">
        <a
          aria-label="View Code"
          id="faGlobe"
          href="https://github.com/MechialH/Byte-Me"
          target="_blank"
          ><fa-icon [icon]="faGlobe" size="2x"></fa-icon
        ></a>
        <p>View<br />Code</p>
      </div> -->
    </div>
  </div>
  <!-- END PROJECT TOP SECTION -->
  <div class="project-pics">
    <div class="two-project-img">
      <div class="wireframe shirt">
        <img src="/assets/Mopar/iPhone13.png" alt="Mopar Dealer Site Mobile" />
      </div>
      <div class="wireframe shirt">
        <img src="/assets/Mopar/iPadMini.png" alt="Mopar Dealer Site Tablet" />
      </div>
    </div>
    <!-- Brand definitions -->
    <div class="brand-define">
      <!-- Row of Fonts -->
      <div class="definition font-define">
        <div class="font">
          <p class="font-eyebrow">Heading</p>
          <img
            src="/assets/JPG/titling-comp.jpg"
            alt="Titling Gothic Compressed Medium"
          />
        </div>
        <div class="font">
          <p class="font-eyebrow">Body — Print</p>
          <img
            src="/assets/JPG/titling-narrow.jpg"
            alt="Titling Gothic Narrow"
          />
        </div>
        <div class="font">
          <p class="font-eyebrow">Body — Web</p>
          <img src="/assets/JPG/ibm-plex.jpg" alt="IBM Plex" />
        </div>
      </div>
      <!-- Row of Swatches -->
      <div class="definition">
        <div class="color mds-primary">
          <p class="color-eyebrow">Primary</p>
          <div class="color-definition">
            <p>
              <strong>C</strong>91 <strong>M</strong>60 <strong>Y</strong>01
              <strong>K</strong>00
            </p>
            <p><strong>HEX</strong> #0067B1</p>
          </div>
        </div>
        <div class="color mds-secondary">
          <p class="color-eyebrow">Secondary</p>
          <div class="color-definition">
            <p>
              <strong>C</strong>81 <strong>M</strong>68 <strong>Y</strong>53
              <strong>K</strong>50
            </p>
            <p><strong>HEX</strong> #293440</p>
          </div>
        </div>
      </div>
    </div>

    <div class="project-img">
      <img
        src="/assets/Mopar/mds-website-mockup.jpg"
        alt="Mopar Dealer Site Screens"
      />
    </div>

    <div class="project-img short">
      <img src="/assets/Mopar/packaging.png" alt="Mopar Parts Packaging" />
    </div>

    <div class="two-project-img">
      <div class="wireframe shirt">
        <img
          src="/assets/Mopar/ca-postcards.png"
          alt="Mopar Holiday Post Cards"
        />
      </div>
      <div class="wireframe shirt">
        <img src="/assets/Mopar/trade-ads.png" alt="Mopar Trade Ads" />
      </div>
    </div>

    <div class="project-img tall">
      <img
        src="/assets/Mopar/magaxine-spreads.jpg"
        alt="Mopar Magazine Spreads"
      />
    </div>

    <!-- End of project images -->
  </div>
</section>
<!-- 
<section class="horizontal-scroll" #cirle (mousemove)="onMouseMove(circle)">
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <img src="/assets/Mopar/spreads/0.jpg" alt="Mopar Magazine Spread" />
  <img src="/assets/Mopar/spreads/1.jpg" alt="Mopar Magazine Spread" />
  <img src="/assets/Mopar/spreads/2.jpg" alt="Mopar Magazine Spread" />
  <img src="/assets/Mopar/spreads/3.jpg" alt="Mopar Magazine Spread" />
  <img src="/assets/Mopar/spreads/4.jpg" alt="Mopar Magazine Spread" />
  <img src="/assets/Mopar/spreads/5.jpg" alt="Mopar Magazine Spread" />
  <img src="/assets/Mopar/spreads/6.jpg" alt="Mopar Magazine Spread" />
  <img src="/assets/Mopar/spreads/7.jpg" alt="Mopar Magazine Spread" />
  <img src="/assets/Mopar/spreads/9.jpg" alt="Mopar Magazine Spread" />
  <img src="/assets/Mopar/spreads/10.jpg" alt="Mopar Magazine Spread" />
</section> -->

<nav #cirle (mousemove)="onMouseMove(circle)">
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <ul class="nav-links">
    <li>
      <a [routerLink]="'/nin'" fragment="bio-anchor"
        ><span class="arrow-left">← </span> <strong>09</strong> Nurses Inspire
        Nurses</a
      >
    </li>
    <li>
      <a [routerLink]="'/dodgegarage'" fragment="bio-anchor"
        ><strong>02</strong> DodgeGarage <span class="arrow-right">→</span></a
      >
    </li>
  </ul>
</nav>
