<app-navigation></app-navigation>

<section class="project-parent" #cirle (mousemove)="onMouseMove(circle)">
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <!-- Left side of project content -->
  <div class="project-info">
    <div class="project-bio">
      <p class="counter"><strong>09/</strong>09</p>
      <p class="project-headline">
        <strong>Nurses Inspire Nurses —</strong> Supporting the young, wild &
        free brand for healthcare providers
      </p>
      <div class="project-hero">
        <img src="/assets/PNG/nin-hero.png" alt="Nurses Inspire Nurses Hero" />
      </div>
      <div class="project-meta">
        <p><strong>PROJECT:</strong> Freelance</p>
        <p><strong>ROLE:</strong> Art Direction</p>
        <p><strong>DATE:</strong> 2019</p>
      </div>
      <p class="project-copy">
        Nurses Inspire Nurses (NIN) is a community that was created to provide
        support and resources for overworked nurses. During the pandemic, the
        brand gained a lot of attention on social media and looked to create a
        visual identity that was vibrant and 'extra' to match their youthful
        audience. Working directly with the creator of NIN, I have designed
        hundreds of social graphics, apparel designs, and care package products
        intended to build the community of support for healthcare providers.
      </p>
    </div>
    <!-- Right side of project content -->
    <div class="project-links">
      <!-- Button #1 -->
      <div class="project-link">
        <a
          aria-label="Back Home"
          id="faArrowLeft"
          [routerLink]="'/portfolio'"
          fragment="bio-anchor"
          ><fa-icon [icon]="faArrowLeft" size="2x"></fa-icon
        ></a>
        <p>Back<br />Home</p>
      </div>
      <!-- Button #2 -->
      <!-- <div class="project-link">
        <a
          aria-label="View Code"
          id="faGlobe"
          href="https://github.com/MechialH/Byte-Me"
          target="_blank"
          ><fa-icon [icon]="faGlobe" size="2x"></fa-icon
        ></a>
        <p>View<br />Code</p>
      </div> -->
    </div>
  </div>
  <!-- END PROJECT TOP SECTION -->
  <div class="project-pics">
    <div class="two-project-img">
      <img
        class="img-wide"
        src="/assets/nin/community.png"
        alt="The Community Banner"
      />
      <img
        class="img-square"
        src="/assets/nin/get-your-ish.png"
        alt="Get Your Ish Togeher"
      />
    </div>

    <div class="project-img png-500">
      <img src="/assets/nin/nin-tees.png" alt="NIN tee shirt designs" />
    </div>

    <div class="two-project-img">
      <img
        class="image-split"
        src="/assets/nin/coffee-front.png"
        alt="NIN Coffee Bag Front"
      />
      <img
        class="image-split"
        src="/assets/nin/coffee-back.png"
        alt="NIN Coffee Bag Back"
      />
    </div>

    <div class="project-img nin-small-img">
      <img src="/assets/nin/personal-site.png" alt="Cat Golden Personal Site" />
    </div>
    <!-- END PROJECTS -->
  </div>
</section>

<nav #cirle (mousemove)="onMouseMove(circle)">
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <ul class="nav-links">
    <li>
      <a [routerLink]="'/grlpwr'" fragment="bio-anchor"
        ><span class="arrow-left">← </span> <strong>08</strong> GRL PWR</a
      >
    </li>
    <li>
      <a [routerLink]="'/mopar'" fragment="bio-anchor"
        ><strong>01</strong> Mopar <span class="arrow-right">→</span></a
      >
    </li>
  </ul>
</nav>
