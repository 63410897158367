<app-navigation></app-navigation>

<section class="project-parent" #cirle (mousemove)="onMouseMove(circle)">
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <!-- Left side of project content -->
  <div class="project-info">
    <div class="project-bio">
      <p class="counter"><strong>05/</strong>09</p>
      <p class="project-headline">
        <strong>The Portfolio Process —</strong> The art of starting over
      </p>
      <div class="project-hero">
        <img
          src="/assets/PNG/portfolio-process-hero.png"
          alt="The Portfolio Process Hero"
        />
      </div>
      <div class="project-meta">
        <p><strong>PROJECT:</strong> Passion</p>
        <p><strong>ROLE:</strong> Ui/UX & Development</p>
        <p><strong>DATE:</strong> 2022</p>
      </div>
      <p class="project-copy">
        What makes a portfolio valuable? Is it the artwork that it contains? Or
        the medium by which it's presented? In deciding to re-do my portfolio
        (again), I thought there was no better way to say "hey, I design
        websites and I can code" than to design a website and then code it.
      </p>
    </div>

    <!-- Right side of project content -->
    <div class="project-links">
      <!-- Button #1 -->
      <div class="project-link">
        <a
          aria-label="Back Home"
          id="faArrowLeft"
          [routerLink]="'/portfolio'"
          fragment="bio-anchor"
          ><fa-icon [icon]="faArrowLeft" size="2x"></fa-icon
        ></a>
        <p>Back<br />Home</p>
      </div>
      <!-- Button #2 -->
      <!-- <div class="project-link">
        <a
          aria-label="View Code"
          id="faGlobe"
          href="https://github.com/MechialH/Byte-Me"
          target="_blank"
          ><fa-icon [icon]="faGlobe" size="2x"></fa-icon
        ></a>
        <p>View<br />Code</p>
      </div> -->
    </div>
  </div>
  <!-- END PROJECT TOP SECTION -->
  <div class="project-pics">
    <div class="project-img contain">
      <img
        src="/assets/portfolio-process/sketches.png"
        alt="Initial draft sketches"
      />
    </div>

    <div class="two-project-img">
      <img src="assets/portfolio-process/Colors.jpg" alt="Color Swatches" />
      <img src="assets/portfolio-process/type-scale.png" alt="Type Scale" />
    </div>

    <div class="project-img contain">
      <img src="/assets/portfolio-process/grids.png" alt="Figma Grids" />
    </div>

    <div class="project-img contain height-90">
      <img src="/assets/portfolio-process/figma.png" alt="Figma Grid" />
    </div>

    <div class="project-img contain">
      <img
        src="/assets/portfolio-process/imac-code.png"
        alt="Code Comparison"
      />
    </div>
  </div>
</section>

<nav #cirle (mousemove)="onMouseMove(circle)">
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <ul class="nav-links">
    <li>
      <a [routerLink]="'/gifs'" fragment="bio-anchor"
        ><span class="arrow-left">← </span> <strong>04</strong> Animated GIFs</a
      >
    </li>
    <li>
      <a [routerLink]="'/byte-me'" fragment="bio-anchor"
        ><strong>06</strong> Byte Me <span class="arrow-right">→</span></a
      >
    </li>
  </ul>
</nav>

<!-- OLD CODE -->
<!-- 
<div class="project-words-container">
  <div class="project-header">
    <h1>Byte Me</h1>
  </div>
  <div class="project-details">
    <p>
      After talking with food-gurus and at-home-chefs alike, we wanted to be
      certain that this app served as a tool at every step of the cooking
      process. Unlike most popular recipe apps that are over-bloated with blog
      posts or the dull applications that have great resources but a horrible
      user experience, Byte Me thrives in the middle and presents useful tools
      with a modern "refined-palette" aesthetic. <br /><br />The appearance of
      a dish is just as important as the first "byte", so why should that
      standard differ for the app that its served on?
    </p>

    <div class="project-hero-img" id="byte-me-project-hero">
      <img src="assets/PNG/byte-me-hero.png" alt="Byte Me Hero" />
    </div>
  </div>
</div>
Back Button
  <div class="project-buttons">
    <a class="back" [routerLink]="'/portfolio'" aria-label="Back To Portfolio"
      ><fa-icon [icon]="faArrowLeft" size="2x"></fa-icon
    ></a>
    <p>Back</p>
    <a
      class="view-code"
      href="https://github.com/MechialH/Byte-Me"
      target="_blank"
      alt="Github Repo"
      ><fa-icon [icon]="faGlobe" size="2x"></fa-icon
    ></a>
    <p>VIEW CODE</p>

    <div class="project-info">
      <div class="project-tags">
        <div class="tag team"><p>Team Project</p></div>
        <div class="tag grand-circus"><p>Grand Circus</p></div>
      </div>
      <h2>Technologies Used</h2>
      <p>
        Angular 11, HTML, CSS, JavaScript (ES6), Edamam API, Spoonacular API,
        Convert-unit API, FireBase API, Sentry.io API
      </p>
      <h2>Contributors</h2>
      <p>Paige Blakeslee, Mechial Harrison, Maggie Oudsema.</p>
    </div>
  </div>
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
</section>
-->
