<section id="skills-parent" #circle (mousemove)="onMouseMove(circle)">
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <!-- Start Applications -->
  <div class="apps">
    <!-- Apps with icons -->
    <div class="app">
      <div class="app-name">
        <img src="/assets/SVG/figma-icon.svg" alt="Figma icon" />
      </div>
      <p class="description">Figma</p>
    </div>
    <div class="app">
      <div class="app-name">
        <img src="/assets/SVG/sketch-icon.svg" alt="Sketch icon" />
      </div>
      <p class="description">Sketch</p>
    </div>
    <!-- Apps with abbreviations -->
    <div class="app">
      <div class="app-name">
        <p class="app-title">Ai</p>
      </div>
      <p class="description">Illustrator</p>
    </div>
    <div class="app">
      <div class="app-name">
        <p class="app-title">Ps</p>
      </div>
      <p class="description">Photoshop</p>
    </div>
    <div class="app">
      <div class="app-name">
        <p class="app-title">Id</p>
      </div>
      <p class="description">InDesign</p>
    </div>
  </div>
  <!-- End Applications -->
  <!-- Start Languages -->
  <div class="apps">
    <div class="app">
      <p class="app-title language">HTML5</p>
      <p class="description">HTML</p>
    </div>
    <div class="app">
      <p class="app-title language">CSS3</p>
      <p class="description">CSS</p>
    </div>
    <div class="app">
      <p class="app-title language">ES6</p>
      <p class="description">JavaScript</p>
    </div>
  </div>
  <!-- End Languages -->
</section>
