import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mds',
  templateUrl: './mds.component.html',
  styleUrls: ['./mds.component.css']
})
export class MdsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
