import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { faArrowLeft, faGlobe } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-nin',
  templateUrl: './nin.component.html',
  styleUrls: ['./nin.component.css']
})
export class NinComponent implements OnInit {
  faArrowLeft = faArrowLeft;
  faGlobe = faGlobe;

  x = 0;
  y = 0;
  
  @ViewChild('circle') circle: any;

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: any) {
    this.x = e.pageX - 30;
    this.y = e.pageY - 30;
    console.log(this.x, this.y);
  }
  constructor() {}

  ngOnInit(): void {}
}