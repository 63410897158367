<header id="bio-anchor" class="nav-hover">
  <div class="logo-img">
    <a [routerLink]="'/portfolio'" aria-label="Home"
      ><img src="/assets/SVG/peb-logo.svg" alt="PEB logo"
    /></a>
  </div>
  <nav>
    <ul class="nav-links">
      <li>
        <a [routerLink]="'/portfolio'" fragment="work-anchor"
          >WORK <span class="arrow-right">→</span></a
        >
      </li>
      <li>
        <a [routerLink]="'/portfolio'" fragment="skills-parent"
          >ABOUT <span class="arrow-right">→</span></a
        >
      </li>
      <li>
        <a id="secret-star" [routerLink]="'/secrets'"
          ><img src="assets/PNG/star.png" alt="gold star"
        /></a>
      </li>
    </ul>
  </nav>
</header>
