<div
  class="portfolio-parent"
  id="work-anchor"
  #circle
  (mousemove)="onMouseMove(circle)"
>
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <div class="work">
    <!-- First Row -->
    <div class="uneven-row">
      <!-- Mopar -->
      <a routerLink="/mopar" aria-label="Mopar" class="large-col royal-fill">
        <img src="/assets/PNG/magazine.png" alt="Mopar" />
        <!-- HIDDEN UNTIL HOVER -->
        <div class="card-rollover">
          <div class="card-header">
            <p class="card-eyebrow"><strong>01/</strong>09</p>
            <p class="card-title-large">Mopar</p>
          </div>
          <div class="card-footer-large">
            <p class="card-type">PROFESSIONAL</p>
            <div class="card-arrow" id="faArrowRight">
              <fa-icon [icon]="faArrowRight" size="2x"></fa-icon>
            </div>
          </div>
        </div>
      </a>
      <div class="small-col">
        <!-- DodgeGarage -->
        <a
          routerLink="/dodgegarage"
          aria-label="DodgeGarage"
          class="square-image red-fill"
        >
          <img src="/assets/PNG/DG-homepage-hero.png" alt="DodgeGarage" />
          <!-- HIDDEN UNTIL HOVER -->
          <div class="card-rollover">
            <div class="card-header">
              <p class="card-eyebrow"><strong>02/</strong>09</p>
              <p class="card-title-small">DodgeGarage</p>
            </div>
            <div class="card-footer-small">
              <p class="card-type">PROFESSIONAL</p>
              <div class="card-arrow" id="faArrowRight">
                <fa-icon [icon]="faArrowRight" size="2x"></fa-icon>
              </div>
            </div>
          </div>
        </a>
        <!-- Luxwall -->
        <a
          routerLink="/luxwall"
          aria-label="LuxWall"
          class="rectangle-image blue-fill"
        >
          <img src="/assets/PNG/luxwall-hero.png" alt="LuxWall" />
          <!-- HIDDEN UNTIL HOVER -->
          <div class="card-rollover">
            <div class="card-header">
              <p class="card-eyebrow"><strong>03/</strong>09</p>
              <p class="card-title-medium">LuxWall</p>
            </div>
            <div class="card-footer-medium">
              <p class="card-type">PROFESSIONAL</p>
              <div class="card-arrow" id="faArrowRight">
                <fa-icon [icon]="faArrowRight" size="2x"></fa-icon>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <!-- Second Row -->
    <div class="full-width-row">
      <!-- SMPLFD Hero -->
      <a
        routerLink="/gifs"
        aria-label="Animated Gifs"
        class="rectangle-image orange-fill"
      >
        <img src="/assets/PNG/smplfd-hero.png" alt="Animated Gifs" />
        <!-- HIDDEN UNTIL HOVER -->
        <div class="card-rollover">
          <div class="card-header">
            <p class="card-eyebrow"><strong>04/</strong>09</p>
            <p class="card-title-medium">Aimated Gifs</p>
          </div>
          <div class="card-footer-medium">
            <p class="card-type">FREELANCE</p>
            <div class="card-arrow" id="faArrowRight">
              <fa-icon [icon]="faArrowRight" size="2x"></fa-icon>
            </div>
          </div>
        </div>
      </a>
      <!-- Portfolio Process -->
      <a
        routerLink="/process"
        aria-label="Portfolio Process"
        class="rectangle-image purple-fill"
      >
        <img
          src="/assets/PNG/portfolio-process-hero.png"
          alt="Portfolio Process"
        />
        <!-- HIDDEN UNTIL HOVER -->
        <div class="card-rollover">
          <div class="card-header">
            <p class="card-eyebrow"><strong>05/</strong>09</p>
            <p class="card-title-medium">The Portfolio Process</p>
          </div>
          <div class="card-footer-medium">
            <p class="card-type">PASSION</p>
            <div class="card-arrow" id="faArrowRight">
              <fa-icon [icon]="faArrowRight" size="2x"></fa-icon>
            </div>
          </div>
        </div>
      </a>
      <!-- Byte Me -->
      <a
        routerLink="/byte-me"
        aria-label="Byte Me"
        class="rectangle-image green-fill"
      >
        <img src="/assets/PNG/byte-me-hero.png" alt="Byte Me" />
        <!-- HIDDEN UNTIL HOVER -->
        <div class="card-rollover">
          <div class="card-header">
            <p class="card-eyebrow"><strong>06/</strong>09</p>
            <p class="card-title-medium">Byte Me</p>
          </div>
          <div class="card-footer-medium">
            <p class="card-type">ACADEMIC</p>
            <div class="card-arrow" id="faArrowRight">
              <fa-icon [icon]="faArrowRight" size="2x"></fa-icon>
            </div>
          </div>
        </div>
      </a>
    </div>
    <!-- Third Row -->
    <div class="uneven-row">
      <div class="small-col align-right">
        <!-- Playground -->
        <a
          routerLink="/playground"
          aria-label="Playground"
          class="rectangle-image black-fill"
        >
          <img src="/assets/PNG/playground-hero.png" alt="Playground" />
          <!-- HIDDEN UNTIL HOVER -->
          <div class="card-rollover">
            <div class="card-header">
              <p class="card-eyebrow"><strong>07/</strong>09</p>
              <p class="card-title-medium">The Playground</p>
            </div>
            <div class="card-footer-medium">
              <p class="card-type">PASSION</p>
              <div class="card-arrow" id="faArrowRight">
                <fa-icon [icon]="faArrowRight" size="2x"></fa-icon>
              </div>
            </div>
          </div>
        </a>
        <!-- GRL PWR -->
        <a
          routerLink="/grlpwr"
          aria-label="GRL PWR"
          class="square-image pink-fill"
        >
          <img src="/assets/PNG/GRLPWR-hero.png" alt="GRL PWR" />
          <!-- HIDDEN UNTIL HOVER -->
          <div class="card-rollover">
            <div class="card-header">
              <p class="card-eyebrow"><strong>08/</strong>09</p>
              <p class="card-title-small">GRL PWR</p>
            </div>
            <div class="card-footer-small">
              <p class="card-type">FREELANCE</p>
              <div class="card-arrow" id="faArrowRight">
                <fa-icon [icon]="faArrowRight" size="2x"></fa-icon>
              </div>
            </div>
          </div>
        </a>
      </div>
      <!-- Nurses Inspire Nurses -->
      <a
        routerLink="/nin"
        aria-label="Nurses Inspire Nurses"
        class="large-col yellow-fill"
      >
        <img src="/assets/PNG/nin-hero.png" alt="Nurses Inspire Nurses" />
        <!-- HIDDEN UNTIL HOVER -->
        <div class="card-rollover">
          <div class="card-header">
            <p class="card-eyebrow"><strong>09/</strong>09</p>
            <p class="card-title-large">Nurses Inspire Nurses</p>
          </div>
          <div class="card-footer-large">
            <p class="card-type">FREELANCE</p>
            <div class="card-arrow" id="faArrowRight">
              <fa-icon [icon]="faArrowRight" size="2x"></fa-icon>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>

<router-outlet></router-outlet>

<!-- OLD GALLERY -->

<!-- <a
    routerLink="/playground"
    aria-label="Playground"
    class="project-container purple"
  >
    <img
      src="assets/SVG/peb-transparent.svg"
      class="logo-hero"
      alt="The Playground"
    />
  </a> -->
<!-- Ho Ho-->
<!-- <a
    routerLink="/hoho"
    aria-label="Ho Ho Oh No You Don't"
    class="project-container red"
  >
    <img
      src="assets/PNG/hoho-main.png"
      class="work-hero"
      alt="Ho Ho Oh No You Don't"
    /> -->
<!-- <h2 class="work-title">Ho Ho Oh No You Don't</h2> -->
<!-- </a> -->
<!-- GC Connect -->
<!-- <a
    routerLink="/gc-connect"
    aria-label="GC-Connect"
    class="project-container navy"
  >
    <img src="assets/PNG/gc-hero.png" class="work-hero" alt="GC Connect" /> -->
<!-- <h2>GC Connect</h2> -->
<!-- </a> -->
<!-- Mopar -->
<!-- <a routerLink="/mopar" aria-label="Mopar" class="project-container blue">
    <img src="assets/PNG/magazine.png" class="work-hero" alt="Mopar Magazine" /> -->
<!-- <h2>Mopar</h2> -->
<!-- </a> -->
<!-- Nurses Inspire Nurses -->
<!-- <a
    routerLink="/nin"
    aria-label="Nurses Inspire Nurses"
    class="project-container yellow"
  >
    <img
      src="assets/SVG/nin-logo.svg"
      class="logo-hero"
      alt="Ho Ho Oh No You Don't"
    />
  </a> -->
<!-- MDS Redesign -->
<!-- <a
    routerLink="/mds"
    aria-label="Mopar Dealer Site Redesign"
    class="project-container white"
  >
    <img
      src="assets/PNG/mds-hero.png"
      class="mds-img"
      alt="Mopar Dealersite Hero"
    />
  </a> -->
<!-- Byte Me -->
<!-- <a routerLink="/byte-me" aria-label="Byte Me" class="project-container green">
    <img
      src="assets/PNG/byte-me-hero.png"
      class="work-hero"
      alt="Ho Ho Oh No You Don't"
    />
  </a> -->
<!-- DodgeGarage -->
<!-- <a
    routerLink="/dodgegarage"
    aria-label="DodgeGarage"
    class="project-container baby-blue"
  >
    <img
      src="assets/PNG/accessibility-hero.png"
      class="work-hero"
      alt="DodgeGarage Project Page"
    />
  </a> -->
<!-- GIF Space -->
<!-- <a
    routerLink="/gifs"
    aria-label="Gif Space"
    class="project-container baby-blue"
  >
    <img
      src="assets/PNG/accessibility-hero.png"
      class="work-hero"
      alt="Gifs Space"
    />
  </a> -->
