import { Component, HostListener, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-dark-nav',
  templateUrl: './dark-nav.component.html',
  styleUrls: ['./dark-nav.component.css'],
})
export class DarkNavComponent implements OnInit {
  x = 0;
  y = 0;

  @ViewChild('circle') circle: any;

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: any) {
    this.x = e.pageX - 30;
    this.y = e.pageY - 30;
    console.log(this.x, this.y);
  }

  constructor() {}

  ngOnInit(): void {}
}
