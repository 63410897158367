import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { faArrowUp, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  faArrowUp = faArrowUp;
  x = 0;
  y = 0;

  @ViewChild('circle') circle: any;

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: any) {
    this.x = e.pageX - 30;
    this.y = e.pageY - 30;
    console.log(this.x, this.y);
  }
  
  constructor() {}

  ngOnInit(): void {
  }

}
