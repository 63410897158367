<app-navigation></app-navigation>

<section class="project-parent" #cirle (mousemove)="onMouseMove(circle)">
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <!-- Left side of project content -->
  <div class="project-info">
    <div class="project-bio">
      <p class="counter"><strong>08/</strong>09</p>
      <p class="project-headline">
        <strong>GRL PWR —</strong> Small projects for small female-owned
        businesses
      </p>

      <div class="project-meta">
        <p><strong>PROJECT:</strong> Freelance</p>
        <p><strong>ROLE:</strong> Art Direction</p>
        <p><strong>DATE:</strong> 2021 - 2022</p>
      </div>
      <p class="project-info">
        Working with a small business is such a fun challenge, because their
        brands are usually in their infancy. And just like a newborn, it's
        important to handle their short visual history gently, and to find a way
        to help it grow. If a business has an entire design team, or just a
        Canva Pro account, my job is to make sure their visual identity is the
        strongest it can be.
      </p>
    </div>

    <!-- Right side of project content -->
    <div class="project-links">
      <!-- Button #1 -->
      <div class="project-link">
        <a
          aria-label="Back Home"
          id="faArrowLeft"
          [routerLink]="'/portfolio'"
          fragment="bio-anchor"
          ><fa-icon [icon]="faArrowLeft" size="2x"></fa-icon
        ></a>
        <p>Back<br />Home</p>
      </div>
      <!-- Button #2 -->
      <!-- <div class="project-link">
        <a
          aria-label="View Code"
          id="faGlobe"
          href="https://github.com/MechialH/Byte-Me"
          target="_blank"
          ><fa-icon [icon]="faGlobe" size="2x"></fa-icon
        ></a>
        <p>View<br />Code</p>
      </div> -->
    </div>
  </div>
  <!-- END PROJECT TOP SECTION -->

  <div class="brands">
    <!-- Mint Hair crafting -->
    <div class="brand">
      <!-- LEFT SIDE -->
      <div class="brand-info">
        <div class="brand-logo mint-icon">
          <img
            src="/assets/small-biz/mint/Mint-Icon.png"
            alt="Mint Hair Crafting Icon"
          />
        </div>
        <p class="brand-name">Mint Hair Crafting</p>
        <!-- <p class="brand-handle">@JustFollowYourArtShop</p> -->
      </div>
      <!-- END LEFT SIDE -->
      <!-- RIGHT SIDE -->
      <div class="brand-projects">
        <div class="project-img max-600">
          <img
            src="/assets/small-biz/mint/mint-window.jpg"
            alt="Mint Hair Crafting Window"
          />
        </div>
        <div class="two-project-img mint-logo">
          <div class="mint-black">
            <img
              src="/assets/small-biz/mint/MINT_Logo_Black.png"
              alt="LuxWall Business Card Front"
            />
          </div>
          <div class="mint-white">
            <img
              src="/assets/small-biz/mint/MINT_Logo_White.png"
              alt="LuxWall Business Card Back"
            />
          </div>
        </div>
        <!-- Brand definitions -->

        <div class="project-img max-600">
          <img
            src="/assets/small-biz/mint/mint-fabric.jpg"
            alt="Mint Hair Crafting Fabric"
          />
        </div>
      </div>
      <!-- END RIGHT SIDE -->
    </div>
    <!-- END MINT HAIR CRAFTING -->

    <!-- Just Follow Your Art -->
    <div class="brand">
      <!-- LEFT SIDE -->
      <div class="brand-info">
        <div class="brand-logo jfya-icon">
          <img
            src="/assets/small-biz/jfya/jfya-logo.png"
            alt="Just Follow Your Art Logo"
          />
        </div>
        <p class="brand-name">Just Follow Your Art</p>
        <!-- <p class="brand-handle">@JustFollowYourArtShop</p> -->
      </div>
      <!-- END LEFT SIDE -->
      <!-- RIGHT SIDE -->
      <div class="brand-projects">
        <div class="project-img max-600">
          <img src="/assets/small-biz/jfya/calendar.jpg" alt="JFYA Calendar" />
        </div>
        <div class="two-project-img">
          <div class="project-img jfya-project">
            <img
              src="/assets/small-biz/jfya/ipad-pro.png"
              alt="JFYA Website iPad"
            />
          </div>
          <div class="project-img jfya-project">
            <img src="/assets/small-biz/jfya/catalog.jpg" alt="JFYA Catalog" />
          </div>
        </div>
        <!-- Brand definitions -->
      </div>
      <!-- END RIGHT SIDE -->
    </div>
    <!-- END JFYA -->

    <!-- Kind Nurse -->
    <div class="brand">
      <!-- LEFT SIDE -->
      <div class="brand-info">
        <div class="brand-logo">
          <img
            src="/assets/small-biz/kind-nurse/kind-nurse-logo.jpg"
            alt="Kind Nurse Logo"
          />
        </div>
        <p class="brand-name">Kind Nurse</p>
        <!-- <p class="brand-handle">@JustFollowYourArtShop</p> -->
      </div>
      <!-- END LEFT SIDE -->
      <!-- RIGHT SIDE -->
      <div class="brand-projects">
        <div class="two-project-img">
          <div class="project-img">
            <img
              src="/assets/small-biz/kind-nurse/kind-nurses_Page_5.jpg"
              alt="Kind Nurse Illustration"
            />
          </div>
          <div class="project-img">
            <img
              src="/assets/small-biz/kind-nurse/kind-nurses_Page_7.jpg"
              alt="Kind Nurse Illustration"
            />
          </div>
          <div class="project-img">
            <img
              src="/assets/small-biz/kind-nurse/kind-nurses_Page_6.jpg"
              alt="Kind Nurse Illustration"
            />
          </div>
          <div class="project-img">
            <img
              src="/assets/small-biz/kind-nurse/kind-nurses_Page_8.jpg"
              alt="Kind Nurse Illustration"
            />
          </div>
        </div>
        <div class="project-img max-600">
          <img
            src="/assets/small-biz/kind-nurse/kind-nurse-stickers.png"
            alt="Kind Nurse Stickers"
          />
        </div>
        <div class="two-project-img">
          <div class="project-img sticker sixty-width">
            <img
              src="/assets/small-biz/kind-nurse/life-in-scrubs.png"
              alt="Life In Scrubs"
            />
          </div>
          <div class="project-img eighty-width">
            <img
              src="/assets/small-biz/kind-nurse/life-in-scrubs-stack.png"
              alt="Life In Scrubs Stickers Stack"
            />
          </div>
        </div>
      </div>
      <!-- END RIGHT SIDE -->
    </div>
    <!-- END KIND NURSE -->
    <!-- Discover With De -->
    <div class="brand">
      <!-- LEFT SIDE -->
      <div class="brand-info">
        <div class="brand-logo">
          <img
            src="/assets/small-biz/dwd/dwd-badge.png"
            alt="Discover With De Logo"
          />
        </div>
        <p class="brand-name">Discover With De</p>
        <!-- <p class="brand-handle">@JustFollowYourArtShop</p> -->
      </div>
      <!-- END LEFT SIDE -->
      <!-- RIGHT SIDE -->
      <div class="brand-projects">
        <div class="project-img max-600">
          <img
            src="/assets/small-biz/dwd/iMac.jpg"
            alt="Discover With De Website Desktop"
          />
        </div>
        <div class="two-project-img">
          <div class="project-img jfya-project">
            <img
              src="/assets/small-biz/dwd/dwd-mockups.png"
              alt="Discover With De Website Wireframes"
            />
          </div>
          <div class="project-img jfya-project">
            <img
              src="/assets/small-biz/dwd/dwd-phones.png"
              alt="Discover With De Website iPhone"
            />
          </div>
        </div>
        <div class="project-img max-600">
          <img
            src="/assets/small-biz/dwd/website-mockup.jpg"
            alt="Discover With De Website"
          />
        </div>
      </div>
      <!-- END RIGHT SIDE -->
    </div>
    <!-- END DWD -->

    <!-- END PROJECTS -->
  </div>
</section>

<nav #cirle (mousemove)="onMouseMove(circle)">
  <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
  <ul class="nav-links">
    <li>
      <a [routerLink]="'/playground'" fragment="bio-anchor"
        ><span class="arrow-left">← </span> <strong>07</strong> The
        Playground</a
      >
    </li>
    <li>
      <a [routerLink]="'/nin'" fragment="bio-anchor"
        ><strong>09</strong> Nurses Inspire Nurses
        <span class="arrow-right">→</span></a
      >
    </li>
  </ul>
</nav>
