import { Component, Input, OnInit } from '@angular/core';
import { Playground } from '../playground';

@Component({
  selector: 'app-pg-upload',
  templateUrl: './pg-upload.component.html',
  styleUrls: ['./pg-upload.component.css'],
})
export class PgUploadComponent implements OnInit {
  @Input() pgUpload: Playground | null = null;

  constructor() {}

  ngOnInit(): void {}
}
