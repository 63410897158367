<section class="bio-parent">
  <!-- Left side of bio content -->
  <div class="bio-words-container" #circle (mousemove)="onMouseMove(circle)">
    <div class="circle" [style.top.px]="y" [style.left.px]="x"></div>
    <div class="bio-header">
      <h1>I’m Paige Blakeslee, a young wizard from the Detroitish area.</h1>
    </div>
    <!-- <div class="bio-paragraph">
      <p>And you're watching Disney Channel.</p>
    </div> -->
  </div>
  <!-- Right Side of Bio content -->
  <div class="bio-links-container">
    <!-- Dribbble -->
    <a
      aria-label="Dribbble"
      href="https://dribbble.com/paigeblakeslee"
      target="_blank"
      id="faDribbble"
      ><fa-icon [icon]="faDribbble" size="2x"></fa-icon
    ></a>
    <!-- Github -->
    <!-- <a
      aria-label="Github"
      href="https://github.com/pblakeslee/portfolio"
      target="_blank"
      id="faGithub"
    >
      <fa-icon [icon]="faGithub" size="2x"></fa-icon
    ></a> -->
    <!-- Linkedin -->
    <a
      aria-label="Linkedin"
      href="https://www.linkedin.com/in/paigeblakeslee/"
      target="_blank"
      id="faLinkedin"
    >
      <fa-icon [icon]="faLinkedin" size="2x"></fa-icon
    ></a>
    <!-- Email -->
    <a
      aria-label="Email"
      href="mailto:peblakeslee@gmail.com?subject=Making Magic"
      target="_blank"
      id="faEnvelope"
    >
      <fa-icon [icon]="faEnvelope" size="2x"></fa-icon
    ></a>
  </div>
</section>
